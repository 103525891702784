function handleSubmit() {
    let name = document.getElementById("full_name");
    let email = document.getElementById("email");

    if (name.value === "" || email.value === "") {
        alert('Name and email required');
        return;
    }

    fetch('https://faas-tor1-70ca848e.doserverless.co/api/v1/web/fn-4186ff7e-27d4-4c13-879b-adeb64edec89/default/testphp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: name.value, email: email.value })
    })
        .then(response => response.json())
        .then(response => {
            alert(response);
            console.log(JSON.stringify(response))
        })
        .catch((e) => console.error(e))
    document.getElementById('submit_button').style.visibility = 'hidden';
    return false;
}
